import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { ConfigurationValue } from 'addiction-components';
import { combineLatest, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { NavLink } from '../../../shared/models';
import { SharedModule } from '../../../shared/shared.module';
import { NavigationSelector, UserSelector } from '../../state/app.selectors';

@Component({
	selector: 'datalean-main-navbar',
	templateUrl: './main-navbar.component.html',
	styleUrls: ['./main-navbar.component.scss'],
	standalone: true,
	imports: [CommonModule, StoreModule, CdkAccordionModule, MatExpansionModule, SharedModule, RouterModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavbarComponent {
	@ViewChild(MatAccordion) accordion!: MatAccordion;

	protected configurationService = inject(ConfigurationService);
	private store = inject(Store);
	public hasVersioning$ = this.configurationService.isReady.pipe(
		filter((isReady) => isReady),
		map(() => this.configurationService.getConfigValue('hasVersioning')),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	links$ = this.store.select(UserSelector.selectUserData).pipe(
		filter((data) => !!data),
		switchMap((data) =>
			combineLatest([this.store.select(NavigationSelector.selectNavLinks), this.hasVersioning$]).pipe(
				map(([navLinks, hasVersioning]) => ({ navLinks, hasVersioning })),
				distinctUntilChanged((prev, curr) => {
					const areArraysEqual =
						prev.navLinks.length === curr.navLinks.length &&
						prev.navLinks.every(
							(link, index) => link.label === curr.navLinks[index].label && link.children?.length === curr.navLinks[index].children?.length
						);
					return areArraysEqual && !!data;
				})
			)
		),
		map(({ navLinks, hasVersioning }) => {
			return this.filterVersioningNavlink(hasVersioning, navLinks);
		}),
		shareReplay({ bufferSize: 1, refCount: true })
	);
	activePath$ = this.store.select(NavigationSelector.selectActivePath).pipe(shareReplay({ bufferSize: 1, refCount: true }));

	public checkIfNavLinkSelected(navLink: NavLink, activePath?: string | null): boolean {
		if (!activePath) return false;

		if (!navLink.children) {
			return navLink.path == activePath;
		}

		return !!navLink.children.find((childLink) => childLink.path == activePath);
	}

	trackBy(index: number, links: NavLink): string {
		// console.log('links', links);
		// console.log('index', index);
		return links.label;
	}

	/**
	 * Filtra i link di navigazione in base alla presenza del versioning
	 *
	 * @param hasVersioning - Il valore di configurazione che indica la presenza del versioning.
	 * @param navLinks - array opzionale di link di navigazione da filtrare.
	 * @Ritorno Un array di link di navigazione filtrati in base alle condizioni.
	 */
	public filterVersioningNavlink(hasVersioning: ConfigurationValue | null, navLinks?: NavLink[]): NavLink[] {
		if (!navLinks) return [];

		return navLinks.filter((navLink) => {
			if (navLink.checkVersioning && !hasVersioning) return null;

			if (navLink.children) {
				navLink.children = this.filterVersioningNavlink(hasVersioning, navLink.children);
			}

			return navLink;
		});
	}
}
